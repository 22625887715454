import serverCheck from "../modules/serverCheck";
import toast from "../modules/toast";
import BaseService from "./BaseService";

class AvalaraService extends BaseService {
  baseUrl = `${serverCheck.getServer().serverCustomer}avalara`;

  cacheOptions = {
    prefix: "avalara",
    ttl: 5 // 5 minutes, no more than 1 company in Avalara. Would be best to have small ttl for first integration
  };

  async queryCompanies() {
    return wrapWithErrorHandler(async () => {
      const res = await this.httpClient.get("/companies");
      return res.data.data.records;
    });
  }

  async queryAuditSuccess(data) {
    return wrapWithErrorHandler(async () => {
      const res = await this.httpClient.post("/audit-trail/success", data);
      return res.data.data;
    });
  }

  async queryAuditFailed(data) {
    return wrapWithErrorHandler(async () => {
      const res = await this.httpClient.post("/audit-trail/failed", data);
      return res.data.data;
    });
  }
}

const wrapWithErrorHandler = async (cb) => {
  try {
    return await cb();
  } catch (error) {
    toast.displayToast({
      type: "error",
      titleText: "Error",
      bodyMessageText: error.response?.data?.errorMessage ?? error.message
    });
    throw error;
  }
};

const avalaraService = new AvalaraService();

export default avalaraService;
